import React from "react"
import styles from "./Layout.module.scss"
import LayoutHeader from "../LayoutHeader"
import LayoutFooter from "../LayoutFooter"
import LayoutDestModal from "./LayoutDestModal"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children, location, destination }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let layoutHasSidebar = false
  // regexp: "/dest/hanoi/a" | "/docs"
  if (location.pathname.match(/^\/(dest\/[a-zA-Z]+\/a\/|docs)/)) {
    layoutHasSidebar = true
  }
  // console.log(
  //   "PATHNAME",
  //   location && location.pathname,
  //   "SIDEBAR",
  //   layoutHasSidebar
  // )

  return (
    <div className={styles.flexColumn}>
      <LayoutHeader
        location={location}
        siteTitle={data.site.siteMetadata.title}
        destination={destination}
      />
      <LayoutDestModal />

      <div className={styles.flexContainer}>{children}</div>
      <LayoutFooter layoutHasSidebar={layoutHasSidebar} />
    </div>
  )
}

export default Layout
