import React from "react"
import styles from "./LayoutFooter.module.scss"
import { Link } from "gatsby"
import classNames from "classnames/bind"
import footerNav from "../../../content/footerNav.yml"
// import MetaTitle from "../MetaTitle"
// import SectionLinks from "./SectionLinks"

const cx = classNames.bind(styles)

const LayoutFooter = ({ layoutHasSidebar }) => {
  const fullYear = new Date().getFullYear()

  return (
    <footer className={styles.footerContainer}>
      <div className={"sectionContainer"}>
        <div className={cx("footerSection", { layoutHasSidebar })}>
          <div className={styles.navSection}>
            {footerNav.items.map(item => {
              return item.external ? (
                <a
                  href={item.to}
                  key={item.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx("footerLink")}
                >
                  {item.title}
                </a>
              ) : (
                <Link
                  to={item.to}
                  key={item.title}
                  className={cx("footerLink")}
                >
                  {item.title}
                </Link>
              )
            })}
          </div>

          <section className={cx("copyrightWrapper")}>
            <p className={cx("footerCopyright")}>
              {`Copyright © ${fullYear} TripTo Team, All rights reserved.`}
              <a
                href="https://tripto.apptilus.com/admin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {` (ADMIN)`}
              </a>
            </p>
          </section>
        </div>
      </div>
    </footer>
  )
}

export default LayoutFooter
