import React, { useContext } from "react"
import UIStateContext from "../../context/UIStateContext"
import styles from "./LayoutDestModal.module.scss"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const LayoutDestModal = () => {
  const uiState = useContext(UIStateContext)

  return (
    <div
      className={cx("modalContainer")}
      style={{
        display: uiState.state.toggleDest ? "block" : "none",
      }}
    >
      <div className={cx("modalCityList")}>
        <div style={{ color: "white", padding: "10px 0" }}>
          <span style={{ visibility: "visible", paddingRight: "10px" }}>
            베트남
          </span>
          하노이
        </div>
        <div style={{ color: "white", padding: "10px 0" }}>
          <span style={{ visibility: "hidden", paddingRight: "10px" }}>
            베트남
          </span>
          호치민
        </div>
        <div style={{ color: "white", padding: "10px 0" }}>태국</div>
        <div style={{ color: "white", padding: "10px" }}>방콕</div>
        <div style={{ color: "white", padding: "10px" }}>푸켓</div>
        <div style={{ color: "white", padding: "10px 0" }}>대만</div>
        <div style={{ color: "white", padding: "10px" }}>가오슝</div>
      </div>
    </div>
  )
}

export default LayoutDestModal
