import React, { useContext, useState } from "react"
import styles from "./LayoutHeader.module.scss"
import { Link } from "gatsby"
import classNames from "classnames/bind"
import UIStateContext from "../../context/UIStateContext"
import logoSvg from "../../icons/fullLogo.svg"
import IconClose from "./close.svg"
// import navHeader from "../../../content/headerNav.yml"
// import HeaderLink from "./HeaderLink"
// import DocSearch from "./DocSearch"
// import IconToggler from "./chevron-down.svg"
// import IconMenu from "./menu.svg"

const cx = classNames.bind(styles)

const LayoutHeader = ({ location, siteTitle, destination }) => {
  const [isSearchFocused] = useState(false)
  const uiState = useContext(UIStateContext)
  const isToggled = uiState.state.toggleDest

  // console.log(location.pathname, destination)

  // const _handleClick = event => {
  //   uiState.action.setToggleDest(!isToggled)
  //   document.body.classList.toggle("body-modal-open")
  // }

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.headerSection}>
          {/* Logo And Title */}
          <Link to="/" className={styles.logoTitle}>
            <img src={logoSvg} alt="logo" height="50px" />
            {/* <span className={styles.title}>{siteTitle}</span> */}
          </Link>

          {/* TODO: nav 아님 div로 수정 */}
          <nav className={styles.navSection}>
            <div
              className={cx("toggleSection")}
              // onClick={_handleClick}
              style={{ visibility: isSearchFocused ? "hidden" : "visible" }}
            >
              {destination || "어디로 떠나시나요?"}
              {/* TODO: 목적지 추가한 뒤 추가 */}
              {/* 어디로 떠나시나요? */}
              {/* <img
                src={IconToggler}
                alt="chevron-down"
                className={cx("toggleIcon")}
                style={{
                  transform: isToggled && "rotate(180deg)",
                }}
              /> */}
            </div>
          </nav>

          {/* TODO: 검색 기능 구현 */}
          {/* <DocSearch
            display={isToggled ? "none" : "flex"}
            setFocused={setIsSearchFocused}
          /> */}

          <div className={styles.headerRightSection}>
            {/* TODO: 메뉴 버튼 구현, 메뉴에 넣을 항목 구상 */}
            {/* TODO: 미디어 사이즈에 따라 메뉴 버튼 혹은 메뉴 리스트 보이기 */}
            {/* <Link
              to="/"
              className={styles.menuLink}
              style={{ display: isToggled ? "none" : "flex" }}
            >
              <img src={IconMenu} alt="menu" height="20px" />
            </Link> */}
            {/* {navHeader.items.map(item => (
              <HeaderLink
                key={item.title}
                // TODO
                // isActive={location.pathname.includes(item.activeSelector)}
                title={item.title}
                to={item.to}
              />
            ))} */}
            <div
              // onClick={_handleClick}
              className={styles.closeBtn}
              style={{ display: isToggled ? "block" : "none" }}
            >
              <img src={IconClose} alt="menu" height="26px" />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default LayoutHeader
